<template>
  <base-list-view
    @state-scroll="scrollList"
  >
    <template v-slot:filter-slot>
      <up-input-filter
        @onSearch="(e) => startSearch(e)"
      />
    </template>
    <div class="participant-list">
      <div
        v-for="participant in participantsList"
        :key="participant.id"
        class="participant-item"
      >
        <participant-card
          :id="participant.id"
          :photo="participant.photo"
          :name="participant.name"
          :company="participant.company"
          :position="participant.position"
          :description="participant.description"
          :full-prop="participant"
        />
      </div>
      <system-card
        v-if="systemMessage"
        :system-message="systemMessage"
        :cbFunc="repeatGetPage"
      />
      <pre-loader v-if="isLoading"/>
    </div>
  </base-list-view>
</template>
<script>
import { DateTime } from 'luxon';
import appBaseNav from '@/components/mixins/appBaseNav';
import participants from '@/components/mixins/methodsParticipants';
import banners from '@/components/mixins/methodsBanners';

import baseListView from '@/components/shared/baseListView.vue';
import SystemCard from '@/components/shared/upCard/SystemCard.vue';

export default {
  name: 'ListParticipantsView',
  components: { baseListView, SystemCard },
  props: {
    launchDownload: {
      type: Boolean,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      participantsList: [],
      isLoading: true,
      curPage: 1,
      countPage: null,
      searchInput: null,

      isLastDownloaded: false,
      systemMessage: null,
    };
  },
  mixins: [appBaseNav, participants, banners],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('programList.header_title'));
    },
  },
  computed: {

  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('participantList.header_title'));

    Promise.all([
      this.getBanner('participant', this.$store.getters.getBannerParticipantTs),
    ])
      .then(([
        banner,
      ]) => {
        this.isLoading = false;
        if (banner && Object.keys(banner).length) {
          this.$emit('showBanner', banner);
          const nowMilis = DateTime.now().toMillis();
          localStorage.setItem('bannerParticipantTs', nowMilis);
          this.$store.commit('setBannerParticipantTs', nowMilis);
        }

        this.getPage();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  },
  methods: {
    getPage() {
      if (this.countPage === null || this.curPage <= this.countPage) {
        this.isLoading = true;

        this.getParticipantList(this.curPage, this.searchInput)
          .then((data) => {
            this.isLoading = false;
            this.$emit('turnOff', {
              stateDownload: false,
              stateCheckInbox: false,
            });
            if (data?.results && Array.isArray(data.results)) {
              this.participantsList = [...this.participantsList, ...data.results];
              this.countPage = data.num_pages;
              this.curPage = data.page;
              this.isLastDownloaded = true;
              this.systemMessage = null;
            } else {
              this.isLastDownloaded = false;
              this.systemMessage = this.$t('system.bad_connect');
            }
          })
          .catch((error) => {
            console.error('getPage()::', error);
          });
      }
    },
    scrollList(prop) {
      if (prop.endPage && !this.isLoading && this.isLastDownloaded) {
        this.curPage += this.isLastDownloaded ? 1 : 0;
        this.getPage();
      }
    },
    repeatGetPage() {
      this.getPage();
    },
    startSearch(data) {
      this.curPage = 1;
      this.participantsList = [];
      this.searchInput = data;
      this.getPage();
    },
  },
};
</script>
<style lang="scss">
.participant {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  &-list &-item {
    flex: 1 1 140px;
    max-width: calc(50% - 6px);
    @media (min-width: 480px) {
      flex: 1 1 180px;
      max-width: calc(33.333% - 6px);
    }

    & .upCard {
      border-radius: 16px;
    }
  }
}
</style>
