import api from '@/components/mixins/baseApi';
import { DateTime } from 'luxon';

export default {
  mixins: [api],

  methods: {
    // meetingTimeSlot(userID) {
    meetingTimeSlot() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        // .then((data) => {
        //   console.log('meetingTimeSlot', data);
        //   return this.generateTimeSlot();
        // })
        .then(() => this.generateTimeSlot())
        .catch((error) => {
          console.error(error);
        });
    },
    meetingTimeReserve() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        .then(() => {

        })
        .catch((error) => {
          console.error(error);
        });
    },
    getMeetingList() {
      // https://plusforum.expoup.ru/wapi/v1/ru/5/mm/page
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/mm/page`,
      })
        .then((data) => data)
        .catch((error) => {
          console.error(error);
        });
    },
    getMeetingByUser(userID) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/mm/byuser/${userID}`,
      })
        .then((data) => data.results)
        .catch((error) => {
          console.error(error);
        });
    },
    getMeeting(meetingID) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/mm/meeting/${meetingID}`,
      })
        .then((data) => data.results)
        .catch((error) => {
          console.error(error);
        });
    },
    saveMeeting(meetParams) {
      const {
        uuid: meetingID,
        invited_user_id: user,
        start_ts: start,
        duration_ts: duration,
        // eslint-disable-next-line camelcase
        approve_me: approve,
        message,
      } = meetParams;
      if (meetingID) {
        const SAVE_DATA = start ? { start } : { approve };
        return this.editMeeting(meetingID, SAVE_DATA);
      }
      return this.newMeeting({
        user, start, message, duration,
      });
    },
    newMeeting(meetParams) {
      return this.baseAxios({
        method: 'post',
        url: `${this.$store.getters.apiBasePath}/mm/meeting/`,
        data: { ...meetParams },
      })
        .then(({ results }) => results)
        .catch((error) => {
          console.error(error);
        });
    },
    editMeeting(meetingID, meetParams) {
      return this.baseAxios({
        method: 'put',
        url: `${this.$store.getters.apiBasePath}/mm/meeting/${meetingID}`,
        data: { ...meetParams },
      })
        .then(({ results }) => results)
        .catch((error) => {
          console.error(error);
        });
    },
    declineMeeting(meetingID) {
      return this.baseAxios({
        method: 'delete',
        url: `${this.$store.getters.apiBasePath}/mm/meeting/${meetingID}`,
      })
        .then(({ results }) => results)
        .catch((error) => {
          console.error(error);
        });
    },
    // for develop test
    generateTimeSlot() {
      let time = this.$store.getters.exhTimeStart;
      const START_HOURS = DateTime.fromMillis(time)
        .setZone(this.$store.getters.getTime_UTC)
        .hour;
      const timeEnd = this.$store.getters.exhTimeEnd;

      const arrSlots = [[]];
      let arrSlotIndex = 0;
      while (time < timeEnd) {
        // const isDisabled = Math.random() > 0.75;
        // arrSlots[arrSlotIndex].push({ time, disabled: isDisabled });
        arrSlots[arrSlotIndex].push({ time, disabled: false });
        const dt = DateTime.fromMillis(time).setZone(this.$store.getters.getTime_UTC);

        if (dt.plus({ minutes: 15 }).hour < 18) {
          time = dt.plus({ minutes: 15 }).toMillis();
        } else {
          time = dt.plus({ days: 1 }).setZone(this.$store.getters.getTime_UTC)
            .startOf('day')
            .plus({ hours: START_HOURS })
            .toMillis();
          if (time < timeEnd) arrSlots.push([]);
          arrSlotIndex += 1;
        }
      }

      return arrSlots;
    },
  },
};
