<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    v-if="itemData.type === 'date'"
    :id="doScrollItem"
    class="calendar-day__wrapper"
  >
    <div class="calendar-day">{{ itemData.text }}</div>
  </div>
  <div
    v-else
    class="calendar-item__wrapper"
  >
    <div class="timemark bookmark">
      <i class="exu-clock" />
    </div>
    <div class="calendar-item">
      <up-card>
        <div class="calendar-item__inner">
          <div
            @click="titleHandle"
            class="title-block"
          >
            <p
              v-if="mainTitle"
              class="main-title"
            >
              {{ mainTitle }}
            </p>
            <p
              v-if="!subTitle.isStatusText"
              class="sub-title"
            >
              {{ subTitle.statusText }}
            </p>
          </div>
          <div class="upCard-bottom">
            <div
              @click="titleHandle"
            >
              <div class="time-place">
                <span class="calendar-item-time">
                  {{ timeStartEnd }},
                </span>
                <span class="calendar-item-place">{{ place }}</span>
              </div>
              <div class="system-comment">
                <span>{{ systemComment }}</span>
              </div>
            </div>
          </div>
        </div>
      </up-card>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';

export default {
  name: 'UserCalendarItem',
  props: {
    itemData: {
      type: Object,
      default: () => ({}),
    },
    doScrollItem: null,
  },
  computed: {
    tsToUrlDate() {
      return DateTime.fromMillis(this.itemData.start_ts).toFormat('yyyyLLdd');
    },
    timeStart() {
      return DateTime.fromMillis(this.itemData.start_ts).setZone(this.$store.getters.getTime_UTC);
    },
    timeEnd() {
      return this.timeStart.plus(this.itemData.duration_ts);
    },
    timeStartEnd() {
      return `${this.timeStart.toFormat('H:mm')} - ${this.timeEnd.toFormat('H:mm')}`;
    },
    place() {
      return this.itemData.place || '';
    },
    mainTitle() {
      return this.itemData.main_title;
    },
    meetStatus() {
      if (this.itemData.approve_who && this.itemData.approve_me) return this.$t('meeting.meeting_confirmed');
      if (this.itemData.approve_who === null && this.itemData.approve_me) return this.$t('meeting.waiting_for_confirmation');
      if (this.itemData.approve_me === false) return this.$t('meeting.you_declined');
      if (this.itemData.approve_who === false) return this.$t('meeting.offer_declined');
      return this.$t('meeting.wait_for_your_reply');
    },
    subTitle() {
      const TITLE_DETAILED = (this.itemData.approve_who && this.itemData.approve_me)
       || this.itemData.approve_who;
      return {
        // eslint-disable-next-line max-len
        isStatusText: this.itemData.approve_who === null && this.itemData.approve_me === null,
        statusText: this.meetStatus,
        is_textare: !TITLE_DETAILED && this.itemData.approve_me === null,
      };
      // return this.itemData.sub_title;
    },
    systemComment() {
      if (this.itemData.type === 'meet') return 'Деловая встреча';
      return 'Деловая программа';
    },
    isModerators() {
      return this.itemData.moderators.length > 0;
    },
    isSpeakers() {
      return this.itemData.speakers.length > 0 && !this.isOpenSection;
    },
  },
  mounted() {
    if (this.doScrollItem === this.itemData.id) {
      const ITEM = document.querySelector(`#${this.doScrollItem}`);

      if (ITEM) ITEM.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  },
  methods: {
    markFavorit() {
      this.$emit('mark-favorite', this.dataForMarkFavorit);
    },
    titleHandle() {
      if (this.itemData.hashLink) {
        this.$router.push({ name: 'editMatch', params: { matchId: this.itemData.uuid } });
      }
    },
  },
};
</script>
