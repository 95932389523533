<template>
  <div class="profile__wrapper">
    <profile-top
      :photo="photo"
      :name="name"
      :position="position"
      :department="department"
      :company="company"
      :activityTag="activityTag"
    />
    <div class="profile-system__wrapper">
      <div class="profile-content full-size">
        <div
          v-if="isHotLink"
          class="hot-link-panel"
        >
            <div class="row justify-content-evenly">
              <div
                v-if="isMessenger"
                :class="cssClassHotLink"
              >
                <up-card
                  @click.stop="getChatUuid"
                >
                <div class="hot-link">
                  <i class="exu-message-chat-square" />
                </div>
                </up-card>
              </div>
              <div
                v-if="isMM"
                :class="cssClassHotLink"
              >
                <up-card>
                  <router-link
                    v-if="isMeetUuid"
                    :to="{ name: 'editMatch', params: { matchId: this.participant.meet_uuid } }"
                  >
                    <i class="exu-user-meeting big-i"/>
                  </router-link>
                  <router-link
                    v-else
                    :to="{ name: 'newMatch', params: { participantID: this.id } }"
                  >
                    <i class="exu-user-meeting big-i"/>
                  </router-link>
                </up-card>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="profile-content__wrapper">
      <div
        v-if="description"
        class="profile-content"
      >
        <p class="description-paragraph">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import participants from '@/components/mixins/methodsParticipants';
import methodsChat from '@/components/mixins/methodsChat';
import methodsMeetings from '@/components/mixins/api/apiMethodsMeeting';

export default {
  name: 'ParticipantProfile',
  props: {
    id: {
      requared: true,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      participant: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  mixins: [appBaseNav, participants, methodsChat, methodsMeetings],
  computed: {
    name() {
      const lname = this.participant.lname && this.participant.lname.trim();
      const fname = this.participant.fname && this.participant.fname.trim();
      const sname = this.participant.sname && this.participant.sname.trim();
      return `${lname} ${fname}${sname ? ` ${sname}` : ''}`;
    },
    position() {
      return this.participant.position || '';
    },
    department() {
      return this.participant.department || '';
    },
    company() {
      return this.participant.company || '';
    },
    photo() {
      return this.participant.photo || '';
    },
    activityTag() {
      return this.participant.activity || '';
    },
    description() {
      return this.participant.description || '';
    },
    isMessenger() {
      return this.$store.getters.isMessenger;
    },
    isMM() {
      return this.$store.getters.isMM;
    },
    isMeetUuid() {
      console.log(this.isMM, this.participant.meet_uuid);
      return this.isMM && this.participant.meet_uuid;
    },
    isHotLink() {
      return this.isMessenger || this.isMM;
    },
    cssClassHotLink() {
      if (!this.isMM || !this.isMessenger) return 'col-12';
      return 'col-6';
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('participantProfile.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('participantProfile.header_title'));
    this.getParticipantPersone(this.id)
      .then((result) => {
        this.participant = { ...result };
        return this.getMeetingByUser(this.id);
      })
      .then((result) => {
        const MEET = Array.isArray(result) && result[0] ? result[0] : null;
        if (MEET && MEET.uuid) {
          this.participant.meet_uuid = MEET.uuid;
        }
      });
  },
  methods: {
    getChatUuid() {
      this.getChatRoomByUser(this.id)
        .then((results) => {
          this.$router.push({ name: 'chatView', params: { uuid: results.uuid } });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// .profile-content .hot-link-panel .upCard
.profile {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    min-height: 100%;
    padding-bottom: 24px;
  }
  &-content {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
    &__wrapper {
      display: flex;
      width: 100%;
      max-width: 760px;
      margin: 0 auto;
      align-items: center;
      flex: 3 1 100%;
      align-self: stretch;
    }
    &.full-size {
      width: 100%;
    }
    .hot-link-panel {
      width: 100%;

      .upCard {
        border: 2px solid #356899;
        border-radius: 16px;
        padding: 8px;

        & .inner-text, & .hot-link, & a {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: .5rem;
          text-decoration: none;
          color: #356899;

          & i {
            font-size: 1.4rem;
            &.big-i {
              font-size: 2.4rem;
            }
          }
        }
        & .hot-link {
          height: 100%;
          justify-content: center;
        }
      }
    }
    .description-paragraph {
      color: #95969D;
      // font-family: Roboto;
      // font-size: 14px;
      text-align: justify;
      margin: 0;
    }
  }
  &-system {
    &__wrapper {
      display: flex;
      width: 100%;
      max-width: 760px;
      margin: 0 auto;
      align-items: center;
      flex: 0 1 auto;
      align-self: stretch;
    }
  }
}
.btn-messenger {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 1rem;
  color: #356899;
  border: 2px solid #356899;
  border-radius: 16px;
  background: #fff;
  gap: .5rem;

  & .inner-text {
    display: flex;
    align-items: baseline;
    gap: .5rem;
  }
}
</style>
