<template>
  <base-list-view
    @state-scroll="scrollList"
  >
  <div class="speaker-list">
    <div
      v-for="speaker in speakersList"
      :key="speaker.id"
      class="speaker-item"
    >
        <speaker-card
          :id="speaker.id"
          :photo="speaker.photo"
          :name="speaker.name"
          :company="speaker.company"
          :position="speaker.position"
          :description="speaker.description"
        />
    </div>
    <system-card
      v-if="systemMessage"
      :system-message="systemMessage"
      :cbFunc="repeatGetPage"
    />
    <pre-loader v-if="isLoading"/>
  </div>
  </base-list-view>
</template>
<script>
import { DateTime } from 'luxon';
import appBaseNav from '@/components/mixins/appBaseNav';
import speackers from '@/components/mixins/methodsSpeackers';
import banners from '@/components/mixins/methodsBanners';

import baseListView from '@/components/shared/baseListView.vue';
import SystemCard from '@/components/shared/upCard/SystemCard.vue';

export default {
  name: 'ListSpeakersView',
  components: { baseListView, SystemCard },
  props: {
    launchDownload: {
      type: Boolean,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      speakersList: [],
      isLoading: true,
      curPage: 1,
      countPage: null,
      searchInput: null,

      isLastDownloaded: false,
      systemMessage: null,
    };
  },
  mixins: [appBaseNav, speackers, banners],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('programList.header_title'));
    },
  },
  computed: {

  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('speakerList.header_title'));
    Promise.all([
      this.getBanner('speaker', this.$store.getters.getBannerSpeakerTs),
    ])
      .then(([
        banner,
      ]) => {
        this.isLoading = false;
        if (banner && Object.keys(banner).length) {
          this.$emit('showBanner', banner);
          const nowMilis = DateTime.now().toMillis();
          localStorage.setItem('bannerSpeakerTs', nowMilis);
          this.$store.commit('setBannerSpeakerTs', nowMilis);
        }
        return this.getPage(this.curPage);
      })
      .finally(() => {
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    getPage() {
      this.isLoading = true;
      if (this.countPage === null || this.curPage <= this.countPage) {
        return this.getSpeackersList(this.curPage)
          .then((data) => {
            this.isLoading = false;
            this.$emit('turnOff', {
              stateDownload: false,
              stateCheckInbox: false,
            });
            if (data?.results && Array.isArray(data.results)) {
              this.speakersList = [...this.speakersList, ...data.results];
              this.countPage = data.num_pages;
              this.curPage = data.page;
              this.isLastDownloaded = true;
              this.systemMessage = null;
            } else {
              this.isLastDownloaded = false;
              this.systemMessage = this.$t('system.bad_connect');
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => true);
      }
      this.isLoading = false;
      this.$emit('turnOff', {
        stateCheckInbox: true,
      });
      return true;
    },
    scrollList(prop) {
      if (prop.endPage && !this.isLoading && this.isLastDownloaded) {
        this.curPage += this.isLastDownloaded ? 1 : 0;
        this.getPage();
      }
    },
    repeatGetPage() {
      this.getPage();
    },
  },
};
</script>
<style lang="scss">
.speaker {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  &-list &-item {
    flex: 1 1 140px;
    max-width: calc(50% - 6px);
    @media (min-width: 480px) {
      flex: 1 1 180px;
      max-width: calc(33.333% - 6px);
    }

    & .upCard {
      border-radius: 16px;
    }
  }
}
</style>
