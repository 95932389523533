// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime, Interval } from 'luxon';

export default {
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    tsToUrlDate(ts) {
      return DateTime.fromMillis(ts).setZone(this.$store.getters.getTime_UTC).toFormat('yyyyLLdd');
    },
    startDayTs(ts) {
      return DateTime.fromMillis(ts).setZone(this.$store.getters.getTime_UTC).startOf('day').toMillis();
    },
    timeStart(startTs) {
      return DateTime.fromMillis(startTs).setZone(this.$store.getters.getTime_UTC);
    },
    timeEnd(durationTs) {
      return this.timeStart.plus(durationTs);
    },
    timeStartEnd(timeStart, timeEnd) {
      return `${timeStart.toFormat('H:mm')} - ${timeEnd.toFormat('H:mm')}`;
    },
    dateEvent(timeStart) {
      return timeStart.setLocale(this.lang).toFormat('d MMMM');
    },
    dateEventWeekday(timeStart) {
      return timeStart.setLocale(this.lang).toFormat('cccc, d MMMM');
    },
    dateEventLong(ts) {
      return DateTime.fromMillis(ts).setZone(this.$store.getters.getTime_UTC).setLocale('ru').toFormat('d MMMM yyyy');
    },
    timeEvent(ts) {
      return DateTime.fromMillis(ts).setZone(this.$store.getters.getTime_UTC).setLocale('ru').toFormat('HH:mm');
    },
    testDateSubtitle(startTs, endTs) {
      const start = DateTime.fromMillis(startTs);
      const end = DateTime.fromMillis(endTs);

      if (this.$store.getters.getLang === 'en') {
        return Interval.fromDateTimes(start, end).toLocaleString({
          year: 'numeric', month: 'long', day: 'numeric',
        }, { locale: 'en-GB' });
      }

      return Interval.fromDateTimes(start, end).toLocaleString({
        year: 'numeric', month: 'long', day: 'numeric',
      }, { locale: 'ru-RU' });
    },
  },
};
