<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable max-len -->
<template>
  <div class="calendar__wrapper">
    <div
      v-if="isFilter"
      class="calendar__filter-options"
    >
      <div class="up-btn__line base-3-6-3">
        <button
          class="up-btn up-btn-accent"
          :class="{'active': isFilterActive.isLecture}"
          @click="setFilter('meetings')"
        >
          <span>Лекции</span>
          <i
            v-if="isFilterActive.isLecture"
            class="exu-x-close"
          />
        </button>
        <button
          class="up-btn up-btn-accent"
          :class="{'active': isFilterActive.isMeeting}"
          @click="setFilter('lectures')"
        >
          <span>Встречи</span>
          <i
            v-if="isFilterActive.isMeeting"
            class="exu-x-close"
          />
        </button>
      </div>
    </div>
    <div
      v-if="isCalendar"
      class="calendar"
    >
      <component
        v-for="lecture in lectures"
        :key="`calendar-item-${lecture.id}`"
        :id="lecture.id"
        :is="getCalendarComponent(lecture.type)"
        :item-data="lecture"
        :do-scroll-item="isScrollItem"
        @mark-favorite="onMarkFavorite"
      />
    </div>
    <div v-else>
      <up-card class="card-help">
        <h3>{{ $t('userCalendar.help_title') }}</h3>
        <p v-html="$t('userCalendar.help_string_1', { icon: `<i class='exu-user-meeting'></i>` })" />
        <p v-html="$t('userCalendar.help_string_2', { icon: `<i class='exu-bookmark'></i>` })" />
        <p v-html="$t('userCalendar.help_string_3')" />
      </up-card>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import global from '@/components/mixins/methodsGlobal';
import appBaseNav from '@/components/mixins/appBaseNav';
import calendar from '@/components/mixins/methodsCalendar';
import methodsMeetings from '@/components/mixins/api/apiMethodsMeeting';
import participants from '@/components/mixins/methodsParticipants';
import userCalendarItem from './UserCalendarItem.vue';
import userCalendarMeetItem from './UserCalendarMeetItem.vue';

export default {
  name: 'userCalendar',
  props: {
    locale: {
      type: String,
    },
  },
  data() {
    return {
      speaker: {
        type: Object,
        default: () => ({}),
      },
      meetings: [],
      filter: null,

      urlHash: null,
      nowTs: null,
    };
  },
  components: { userCalendarItem, userCalendarMeetItem },
  mixins: [global, appBaseNav, calendar, methodsMeetings, participants],
  computed: {
    lectures() {
      const CALENDAR_ITEMS = [];
      const DATE_LIST = [];
      const FILTERS = {
        lectures: 'userCalendar',
        meetings: 'meetings',
      };

      const ITEM_LIST = this.filter === null || !FILTERS[this.filter]
        ? [...this.userCalendar, ...this.meetings]
        : [...this[FILTERS[this.filter]]];

      ITEM_LIST
        .sort((itemA, itemB) => (itemA.start_ts !== itemB.start_ts
          ? itemA.start_ts - itemB.start_ts
          : itemA.duration_ts - itemB.duration_ts))
        .filter((item) => item.type !== 'date')
        .forEach((item) => {
          const date = this.dateEventWeekday(this.timeStart(item.start_ts));
          if (!DATE_LIST.includes(date)) {
            DATE_LIST.push(date);
            CALENDAR_ITEMS.push({
              id: this.startDayTs(item.start_ts),
              start_ts: this.startDayTs(item.start_ts),
              type: 'date',
              text: date,
            });
          }
          CALENDAR_ITEMS.push(item);
        });

      return CALENDAR_ITEMS;
    },
    isSection() {
      return this.userCalendar && this.userCalendar.length > 0;
    },
    isMeetings() {
      return this.meetings && this.meetings.length > 0;
    },
    isFilter() {
      return this.isSection && this.isMeetings;
    },
    isCalendar() {
      return this.isSection || this.isMeetings;
    },
    isFilterActive() {
      return {
        isLecture: this.filter === 'lectures' || this.filter === null,
        isMeeting: this.filter === 'meetings' || this.filter === null,
      };
    },
    isScrollItem() {
      const elementId = this.urlHash && this.urlHash.match(/mm-\d*/g);

      return elementId ? elementId[0] : null;
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('userCalendar.header_title'));
    },
  },
  created() {
    this.$store.commit('setPageTitle', this.$t('userCalendar.header_title'));
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('userCalendar.header_title'));
    this.loadUserCalendar();
    this.saveUserCalendar();
    this.sendLogMetrika(this.logMetrika({
      url: this.$route.fullPath,
      action: 'open calendar',
      props: JSON.stringify(this.userCalendar),
    }));
    this.getMeetingList()
      .then((data) => {
        if (data?.results) {
          data.results.forEach((meet) => {
            const persone = meet.invited[0];
            const approve = meet.approve_me && meet.approve_who ? 'Встреча подтверждена' : 'Ожидает подтверждения';
            this.meetings.push({
              id: `mm-${meet.ident}`,
              uuid: meet.uuid,
              duration_ts: meet.duration_ts,
              hashLink: 'aaaa',
              main_title: `Встреча с ${persone.name}`,
              place: null,
              start_ts: meet.start_ts,
              sub_title: approve,
              approve_me: meet.approve_me,
              approve_who: meet.approve_who,
              type: 'meet',
            });
          });
        }
      })
      .catch();
    if (this.$route.hash) {
      this.urlHash = this.$route.hash.replace('#', '');
    }
  },
  methods: {
    getCalendarComponent(type) {
      return type === 'meet' ? 'userCalendarMeetItem' : 'userCalendarItem';
    },

    onMarkFavorite(data) {
      if (this.listMarkedID.includes(data.id)) {
        this.removeFromUserCalendar(data.id);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'remove from calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      } else {
        this.addToUserCalendar(data);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'add to calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      }
    },
    setFilter(userFilter) {
      this.filter = this.filter !== userFilter ? userFilter : null;
    },
    timeNowTs() {
      this.nowTs = DateTime.now().toMillis();
      this.$store.commit('setAppTimer', this.startTimeoutNow());
    },
    startTimeoutNow() {
      if (this.$store.getters.getAppTimer !== null) {
        this.endTimeoutNow();
      }
      return setTimeout(this.timeNowTs, 60000);
    },
    endTimeoutNow() {
      if (this.$store.getters.getAppTimer !== null) {
        clearTimeout(this.$store.getters.getAppTimer);
        this.$store.commit('setAppTimer', null);
      }
    },
  },
  beforeUnmount() {
    this.endTimeoutNow();
  },
};
</script>
<style lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/form.scss';
.calendar {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 760px;
    padding: 24px 12px;
    margin-left: auto;
    margin-right: auto;
  }

  &__filter-options {
    padding: 0 $padding-step;

    .up-btn {
      min-height: auto;
      font-size: .8rem;
      border-radius: $border-radius-inside-large;
      // &:focus {
      //   background: $bg-color-base;
      //   color: $color-primary;
      // }
      &-primary {
        &:not(.active):focus {
          background: $bg-color-base;
          color: $color-primary;
        }
      }
      &-accent {
        &:not(.active):focus {
          background: $bg-color-base;
          color: $color-accent;
        }
      }

      &.active {
        span {
          flex: 1 1 100%;
          padding-left: 1.5em;
        }
        i {
          font-size: 1rem;
        }
      }
    }
  }

  display: flex;
  flex-direction: column;

  &-day {
    &__wrapper {
      display: flex;
    }
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    background: #356899;
    border-radius: 16px;
    color: #FFFFFF;
    text-transform: capitalize;
  }
  &-item {
    &__wrapper {
      &:last-child {
        &::before {
          height: 50%;
        }
      }
      &::before {
        content: '';
        display: block;
        grid-area: solidline;
        background: #356899;
      }
      & .timemark {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area: timemarker;
        border: 2px solid #356899;
        border-radius: 50%;
        background: #FFFFFF;
        font-size: 1rem;

        &.bookmark {
          color: #356899;
          // color: #D76535;
        }
      }

      display: grid;
      grid-template-columns:
        [timemarker-start] 14px [solidline-start] 2px [solidline-end] 14px
        [timemarker-end] 10px [item-start] 1fr [item-end];
      grid-template-rows: [solidline-start item-start] 1fr [timemarker-start] 30px
        [timemarker-end] 1fr [solidline-end item-end];

      position: relative;
      padding-left: 2px;
      gap: 0px;
    }
    grid-area: item;
    padding: 8px 0;

    &__inner {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 14px;
      line-height: 130%;
      // letter-spacing: ;

      & p {
        margin: 0;
      }

      & .time-place {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;
        gap: 0 .5em;
        color: #2c3e50;
        font-style: normal;
        font-weight: 500;
        white-space: nowrap;

        & .calendar-item-time {
          font-weight: 500;
        }
      }
      & .title-block {
        display: flex;
        flex-direction: column;
        gap: .2rem;

        & .main-title {
          color: #356899;
          font-style: normal;
          font-weight: 500;
        }
        & .sub-title {
          color: #356899;
          font-style: normal;
        }
      }
      & .system-comment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #95969D;
      }
      & .up-btn.bookmark, & .btn.bookmark {
        width: 36px;
        height: 36px;
        padding: 0;
        border-width: 1px;
        border-radius: 5px;
        font-size: 1.2rem;
        line-height: 1rem;
        text-align: center;
        border: 2px solid #D76535;
        background: #D76535;
        color: #FFFFFF;
      }
      & .upCard-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: .5rem;
      }
    }
  }
}
.card-help {
  padding: 16px;
  color: #AFB0B6;
  & i.exu-bookmark {
    color: #D76535;
  }
  & i.exu-user-meeting {
    color: #356899;
    font-size: 1.8rem;
  }
  & h3 {
    text-align: center;
  }
  & p {
    max-width: 280px;
    text-align: justify;
    margin: 0 auto;
  }
  & p+p {
    margin-top: .5rem;
  }
}
</style>
