<template>
  <div
    id="listView"
    class="listView__wrapper"
  >
    <div
      v-if="$slots['filter-slot']"
      :class="cssClassFilter"
      class="listView__filter-container">
      <slot name="filter-slot" />
    </div>
    <div class="listView__inner">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseListView',
  props: {
    locale: {
      type: String,
      default: 'ru',
    },
  },
  data() {
    return {
      vectorY: null,
      vectorX: null,
      startPage: null,
      endPage: null,
      diffScroll: 0,
      lastScrollStepPosition: 0,
      lastScrollPosition: 0,

      elMainContent: null,
      elListContent: null,

      scrollTimeoutId: null,
    };
  },
  computed: {
    isTransitionShowFilter() {
      if (this.lastScrollPosition > 74 && this.vectorY < 0) {
        return true;
      }
      return false;
    },
    isTransitionHiddenFilter() {
      if (this.lastScrollPosition > 74 && this.vectorY > 0) {
        return true;
      }
      return false;
    },
    cssClassFilter() {
      const cssClassShow = this.isTransitionShowFilter ? 'scrollToTop' : '';
      const cssClassHidden = this.isTransitionHiddenFilter ? 'scrollToBottom' : '';
      return [cssClassShow, cssClassHidden].join(' ');
    },
  },
  mounted() {
    this.elMainContent = document.getElementById('content__wrapper');
    this.elListContent = document.getElementById('listView');
    this.elMainContent.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.diffScroll = Math.abs(this.lastScrollStepPosition - this.elMainContent.scrollTop);
      if (this.diffScroll > 36) {
        this.vectorY = this.lastScrollStepPosition - this.elMainContent.scrollTop < 0 ? 1 : -1;
        this.lastScrollStepPosition = this.elMainContent.scrollTop;
      }

      const sumScrollAndOffsetH = this.elMainContent.scrollTop + this.elMainContent.offsetHeight;

      this.startPage = this.elMainContent.scrollTop === 0;
      this.endPage = sumScrollAndOffsetH > this.elListContent.offsetHeight;

      this.$emit('state-scroll', {
        vectorY: this.vectorY,
        startPage: this.startPage,
        endPage: this.endPage,
      });
      // console.log(
      //   Math.abs(this.lastScrollPosition - this.elMainContent.scrollTop),
      //   sumScrollAndOffsetH,
      //   this.elListContent.offsetHeight,
      //   {
      //     vectorY: this.vectorY,
      //     startPage: this.startPage,
      //     endPage: this.endPage,
      //   },
      // );
      this.lastScrollPosition = this.elMainContent.scrollTop;
    },
  },
  beforeUnmount() {
    this.elMainContent.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style lang="scss">
.listView {
  &__wrapper {
    display: grid;
    grid-template-columns: [left-line] 1fr [right-line];
    grid-template-rows: [top-line] auto [separate-line] 1fr [bottom-line];
    // gap: 12px;

    width: 100%;
    min-height: 100%;

    & > * {
      padding: 0px 12px;

      &:first-child {
        padding-top: 12px;
      }
      &:last-child {
        padding-bottom: 12px;
      }
    }
  }
  &__filter-container {
    position: sticky;
    top: 0;
    transform: translateY(0);
    z-index: 100;
    grid-column: left-line / right-line;
    grid-row: top-line / separate-line;
    background: linear-gradient(180deg, rgba(246, 246, 250, 1) 75%, rgba(246, 246, 250, 0));
    padding-bottom: 12px;

    &.scrollToTop {
      transform: translateY(0);
      transition-property: transform, padding-top, padding-bottom;
      transition-duration: .5s;
    }
    &.scrollToBottom {
      transform: translateY(-100%);
      transition-property: transform, padding-top, padding-bottom;
      transition-duration: .5s;
    }
  }
  &__inner {
    width: 100%;
    margin: 0 auto;

    & > div {
      max-width: 760px;
      margin: 0 auto;
    }
  }
  &__filter-container + &__inner {
    padding-top: 0;
  }
}
</style>
