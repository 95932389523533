<template>
  <p style="margin-bottom: .25rem;">{{ notificationTitle }}</p>
  <div class="systemNotification__btn-row">
    <up-button
      class="systemNotification__btn btn-decline"
      @click="handleDeclinePwa"
    >
    {{ notificationBtnTextDecline }}
    </up-button>
    <up-button
      class="systemNotification__btn btn-success"
      @click="handleInstallPwa"
    >
    {{ notificationBtnText }} <i class="exu-download-02"/>
    </up-button>
  </div>
</template>
<script>
export default {
  name: 'installPwa',
  data() {
    return {

    };
  },
  computed: {
    notificationTitle() {
      return this.$t('system.install_info');
    },
    notificationBtnText() {
      return this.$t('system.btn_apply_install');
    },
    notificationBtnTextDecline() {
      return this.$t('system.btn_decline_install');
    },
  },
  methods: {
    handleInstallPwa() {
      this.$emit('install-pwa', true);
    },
    handleDeclinePwa() {
      this.$emit('decline-pwa', false);
    },
  },
};
</script>
<style lang="scss">
</style>
