<template>
  <div
    :class="{ 'invisible': !isVisible}"
    class="systemNotification__wrapper"
  >
    <div
      v-for="notification in listNotification"
      :key="`notification-${notification.id}`"
      class="systemNotification"
    >
      <component
        v-if="notification.component"
        :is="notification.component"
      />
    </div>
    <div
      v-if="isVisibleNotification"
      class="systemNotification new-version"
    >
      <div v-html="$t('system.new_version_message')" />
      <div class="systemNotification__btn-row">
        <up-button
          class="systemNotification__btn btn-primary"
          @click="isLatter"
        >
        {{ $t('system.btn_show_later') }}
        </up-button>
        <up-button
          class="systemNotification__btn btn-primary"
          @click="IsShowed"
        >
        {{ $t('system.btn_close') }}
        </up-button>
      </div>
    </div>
    <div
      v-if="installPwaExist"
      class="systemNotification"
    >
      <install-pwa
        @install-pwa="installPwa(true)"
        @decline-pwa="installPwa(false)"
      />
    </div>
    <div
      v-if="updatePWAExist"
      class="systemNotification"
    >
      <update-pwa
        :updatePwaExist="updatePWAExist"
        @update-pwa="updatePWA(true)"
        @denied-update-pwa="updatePWA(false)"
      />
    </div>
  </div>
</template>
<script>
import mixinUpdatePwa from '@/components/mixins/methodsPwaUpdate';
import updatePwa from './updatePwa.vue';
import installPwa from './installPwa.vue';

export default {
  name: 'systemNotification',
  mixins: [mixinUpdatePwa],
  components: { updatePwa, installPwa },
  props: {
    isDropLocalNotification: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listNotification: [],
      isLocalSystemNotification: false,
      showLatter: true,
      showLatterTimeout: null,
    };
  },
  computed: {
    isVisible() {
      // eslint-disable-next-line max-len
      return this.listNotification > 0 || this.updatePWAExist || this.installPwaExist || this.isVisibleNotification;
    },
    isVisibleNotification() {
      // eslint-disable-next-line max-len
      return this.showLatter && this.isLocalSystemNotification !== false && !this.updatePWAExist;
    },
  },
  mounted() {
    if (this.isDropLocalNotification) {
      localStorage.removeItem('isLocalNotification');
    }
    const IS_LOCAL_NOTIFICATION = localStorage.getItem('isLocalNotification');
    this.isLocalSystemNotification = IS_LOCAL_NOTIFICATION === null || IS_LOCAL_NOTIFICATION !== 'isShowed';
  },
  methods: {
    isLatter() {
      if (this.showLatterTimeout) {
        this.removeTimeout();
      }
      this.showLatter = false;
      this.showLatterTimeout = setTimeout(() => {
        this.showLatter = true;
      }, 120000000);
    },
    IsShowed() {
      localStorage.setItem('isLocalNotification', 'isShowed');
      this.isLocalSystemNotification = false;
    },
    removeTimeout() {
      clearTimeout(this.showLatterTimeout);
    },
  },
  beforeUnmount() {
    if (this.showLatterTimeout) {
      this.removeTimeout();
    }
  },
};
</script>
<style lang="scss">
.systemNotification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 760px;
  gap: 6px;
  padding: 12px 16px;
  background: #fefefe;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 4px;

    &.invisible {
      display: none;
    }
  }
  &.new-version {
    max-height: 60vh;
    overflow: hidden auto;
    padding-bottom: 0;
    i {
      font-size: 1.1rem;
      &.big-i {
        font-size: 1.4rem;
      }
      &.exu-user-meeting {
        color: #356899;
      }
      &.exu-bookmark, &.exu-msg-question-sq {
        color: #D76535;
      }
    }
    .systemNotification__btn {
      &-row {
        position: sticky;
        bottom: 0;
        margin: 0 -12px ;
        padding: 0 12px 16px;
        background: rgba(250,250,250,.75);
      }
    }
  }

  .notification-title {
    color: #356899;
    &.title-accent {
      color: #D76535;
    }
  }

  .systemNotification__btn {
    font-size: 1rem;
    padding: 10px 6px;

    &-row {
      display: flex;
      gap: 12px;
    }

    &.btn-primary {
      border-color: #356899;
      color: #356899;
    }
    &.btn-success {
      border-color: #D76535;
      color: #D76535;
    }
    &.btn-decline {
      border-color: #95969D;
      color: #95969D;
    }

    @media (min-width: 350px) {
      & {
        font-size: 1.2rem;
        padding: 10px;
      }
    }
  }
}
</style>
