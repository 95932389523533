<template>
<div
  class="navtab-line__wrapper"
>
  <div
    class="navtab-line"
    @touchend="handleScroll"
  >
    <button
      :disabled="lockLeftBtn"
      @click="handleScrollBtn(-250)"
      class="btn-scroll btn-scrollToLeft"
      :class="{ 'disabled': lockLeftBtn }"
    >
      <i class="exu-chevron-left" />
    </button>
    <div
      :id="idNavTab"
      @touchstart="touchNavTab(true)"
      @touchend="touchNavTab(false)"
      class="navtab-line__scroll"
    >
      <div class="navtab-line__inner">
        <button
          v-for="(tab, index) in listTabs"
          :key="idNavTab+'-'+index"
          :class="[
            tab.class,
            {
              'active': curVal === tab.value,
              'disabled': tab.isDisabled,
              'highlight': isHighlight && highlightVal === tab.value
            }
          ]"
          @click="handleClick(tab.value, tab.isDisabled)"
        >
        {{ tab.name }}
        </button>
      </div>
    </div>
    <button
      :disabled="lockRightBtn"
      @click="handleScrollBtn(250)"
      class="btn-scroll btn-scrollToRight"
      :class="{ 'disabled': lockRightBtn }"
    >
      <i class="exu-chevron-right" />
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: 'NavBtnTab',
  props: {
    idNavTab: {
      type: String,
      default: null,
    },
    tabs: {
      type: Array,
      default: () => ([]),
    },
    curVal: null,
    highlightVal: null,
  },
  data() {
    return {
      targetWrapper: null,
      extrimePosition: {
        left: null,
        right: null,
      },
      touch: {
        start: null,
        move: null,
        end: true,
      },
      metrika: null,
      controlSelected: null,
    };
  },
  computed: {
    isHighlight() {
      return this.curVal !== this.highlightVal && (this.highlightVal !== null);
    },
    currentValue() {
      return this.curVal || false;
    },
    lockLeftBtn() {
      return this.extrimePosition.left;
    },
    lockRightBtn() {
      return this.extrimePosition.right;
    },
    listTabs() {
      if (Array.isArray(this.tabs)) {
        return this.tabs.map((tab) => ({
          name: tab.name,
          value: tab.value,
          class: tab.class ? `btn-tabnav ${tab.class}` : 'btn-tabnav',
          isDisabled: !!tab.disabled,
        }));
      }
      return [];
    },
  },
  updated() {
    if (this.controlSelected !== this.currentValue) {
      this.controlSelected = this.currentValue;
      this.scrollToActive();
    }
  },
  mounted() {
    if (this.idNavTab) {
      this.targetWrapper = document.querySelector(`#${this.idNavTab}`);
      this.targetWrapper.addEventListener('scroll', this.handleScroll);
    }
    this.scrollToActive();
  },
  methods: {
    handleClick(value, disabled) {
      this.handleScroll();
      if (!disabled) {
        this.$emit('change-value', value);
      }
    },
    scrollToActive() {
      const ACTIVE_BTN = this.targetWrapper.querySelector('.active');

      if (ACTIVE_BTN) {
        this.targetWrapper.querySelector('.active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      } else {
        this.targetWrapper.childNodes[0].childNodes[1].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    },
    handleScroll() {
      const SCROLL_RIGHT = this.targetWrapper
        .childNodes[0].offsetWidth - this.targetWrapper.offsetWidth;
      const ITEM_COUNT = this.targetWrapper
        .childNodes[0].childNodes.length - 2;
      this.extrimePosition.left = false;
      this.extrimePosition.right = false;

      if (this.touch.end) {
        if (this.targetWrapper.scrollLeft === 0) {
          this.targetWrapper.childNodes[0].childNodes[1].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          this.extrimePosition.left = true;
        }
        if (SCROLL_RIGHT === Math.round(this.targetWrapper.scrollLeft)) {
          this.targetWrapper.childNodes[0].childNodes[ITEM_COUNT].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          this.extrimePosition.left = false;
        }
      }
    },
    handleScrollBtn(prop) {
      this.targetWrapper.scrollBy({ left: prop, behavior: 'smooth' });
    },
    touchNavTab(prop) {
      if (prop) {
        this.touch.start = true;
        this.touch.end = false;
      } else {
        this.touch.start = false;
        this.touch.end = true;
      }
    },
  },
  beforeUnmount() {
    this.targetWrapper.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
@import '@/assets/style/variables.scss';

.navtab-line {
  display: grid;
  grid-template-columns: [left-side] 48px [left-line] 1fr [right-line] 48px [right-side];
  grid-template-rows: [top-side] 1fr [bottom-side];

  width: 100%;
  height: 100%;
  // padding: 0 24px;
  padding: 0;
  background: $color-base-secondary;
  border-radius: 12px;
  color: #0D0D26;
  overflow: hidden;

  & .btn-scroll {
    display: flex;
    align-items: center;
    grid-row: top-side / bottom-side;
    padding: 4px 8px;
    border: none;
    color: $color-text;
    z-index: 100;
  }
  & .btn-scrollToLeft {
    grid-column: left-side / left-line;
    background: linear-gradient(90deg, rgba(242, 246, 253,.85) 75%, transparent);
    justify-content: start;
  }
  & .btn-scrollToRight {
    grid-column: right-side / right-line;
    background: linear-gradient(90deg, transparent, rgba(242, 246, 253,.85) 25%);
    justify-content: end;
  }

  &__wrapper {
    width: 100%;
    max-width: 760px;
    // height: 64px;
    margin: 0 auto;
    padding: 8px 8px;
    // border-radius: 16px;
    background: $color-base;
    &:first-child {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding-bottom: 4px;
    }
    &:last-child {
      padding-top: 4px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: max-content;
    // height: 100%;
    padding: 4px 50%;
    gap: 0 4px;
  }
  &__scroll {
    grid-row: top-side / bottom-side;
    grid-column: left-side / right-side;
    overflow: auto hidden;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;               /* width of the entire scrollbar */
    }
    &:focus::-webkit-scrollbar {
      width: 4px;
      height: 4px;               /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: rgba(53,104,153,0);        /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(26,51,76,0);    /* color of the scroll thumb */
      border-radius: 2px;       /* roundness of the scroll thumb */
      border: none;  /* creates padding around scroll thumb */
    }
    &:focus::-webkit-scrollbar-track {
      background: rgba(53,104,153,.25);        /* color of the tracking area */
    }

    &:focus::-webkit-scrollbar-thumb {
      background-color: rgba(26,51,76,.25);    /* color of the scroll thumb */
      border-radius: 2px;       /* roundness of the scroll thumb */
      border: none;  /* creates padding around scroll thumb */
    }
  }

  .btn-tabnav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    height: 40px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 8px;
    color: $color-text;
    text-decoration: none;
    background: transparent;
    border:none;

    &.active {
      background: $color-base;
      // color: $color-accent;
    }
    &.disabled {
      color: $color-disabled;
    }
    &.highlight {
      color: $color-primary;
      border: 1px solid $color-primary;
    }
    &.active.disabled {
      background: transparent;
      box-shadow: 0 0 2px $color-disabled;
    }
  }
}
</style>
