import { createRouter, createWebHistory } from 'vue-router';
// import HomeView from '../views/HomeView.vue';
import EventsListView from '../views/pages/EventsList/index.vue';
import DashboardView from '../views/DashboardView.vue';
import LoginView from '../views/LoginView.vue';
import AboutView from '../views/AboutView.vue';
import ListPrograms from '../views/pages/program/ListProgramView.vue';
// import ListPrograms from '../views/pages/ListPrograms.vue';
import SectionDetails from '../views/pages/SectionDetails/index.vue';
import ListSpeakers from '../views/pages/Speakers/ListSpeakersView.vue';
// import ListSpeakers from '../views/pages/ListSpeakers.vue';
import SpeakerProfile from '../views/pages/SpeakerProfile.vue';
// import ListParticipants from '../views/pages/ListParticipants.vue';
import ListParticipants from '../views/pages/Participants/ListParticipantsView.vue';
import ParticipantProfile from '../views/pages/ParticipantProfile.vue';
import EditMatchView from '../views/pages/Matchmaking/EditMatchView.vue';
// import ListPartners from '../views/pages/ListPartners.vue';
import ListPartners from '../views/pages/Partners/ListPartnersView.vue';
import PartnersProfile from '../views/pages/PartnersProfile.vue';
import ListNotification from '../views/pages/ListNotification.vue';
import NotificationView from '../views/pages/NotificationView.vue';
import UserProfile from '../views/pages/UserProfile.vue';
import UserCalendar from '../views/pages/user/UserCalendar.vue';
import MapView from '../views/pages/MapView/index.vue';
import chatView from '../views/pages/ChatView/index.vue';
import StaticPageView from '../views/pages/StaticPage/PageView.vue';
import PageSystem404 from '../views/pages/StaticPage/PageSystem404.vue';

const routes = [
  {
    path: '/events',
    name: 'EventsListView',
    component: EventsListView,
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView,
  },
  {
    path: '/login',
    name: 'loginView',
    component: LoginView,
  },
  {
    path: '/about',
    name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    component: AboutView,
  },
  {
    path: '/program/:date?',
    name: 'program',
    component: ListPrograms,
    props: true,
  },
  {
    path: '/section/:section_id/questions',
    name: 'questions',
    component: SectionDetails,
    props: true,
  },
  {
    path: '/section/:section_id/lecture/:lecture_id/questions',
    name: 'lectureQuestions',
    component: SectionDetails,
    props: true,
  },
  {
    // TODO обработать входящий параметр - page
    path: '/speakers/:page?',
    name: 'speakers',
    component: ListSpeakers,
    props: true,
  },
  {
    path: '/speaker/profile/:id',
    name: 'speakerProfile',
    component: SpeakerProfile,
    props: true,
  },
  {
    path: '/participants/:page?',
    name: 'participants',
    component: ListParticipants,
    props: true,
  },
  {
    path: '/participant/profile/:id',
    name: 'participantProfile',
    component: ParticipantProfile,
    props: true,
  },
  {
    path: '/match/new/:participantID',
    name: 'newMatch',
    component: EditMatchView,
    props: true,
  },
  {
    path: '/match/edit/:matchId',
    name: 'editMatch',
    component: EditMatchView,
    props: true,
  },
  {
    path: '/partners/:page?',
    name: 'partners',
    component: ListPartners,
    props: true,
  },
  {
    path: '/partner/profile/:id',
    name: 'partnerProfile',
    component: PartnersProfile,
    props: true,
  },
  {
    path: '/notifications/:page?',
    name: 'notifications',
    component: ListNotification,
    props: true,
  },
  {
    path: '/notification/:id',
    name: 'notification',
    component: NotificationView,
    props: true,
  },
  {
    path: '/profile',
    name: 'userProfile',
    component: UserProfile,
  },
  {
    path: '/calendar',
    name: 'userCalendar',
    component: UserCalendar,
    props: true,
  },
  {
    path: '/map/:stand_id?',
    name: 'mapView',
    component: MapView,
    props: true,
  },
  {
    // path: '/chat/:hash_room?',
    path: '/chat/:uuid?',
    name: 'chatView',
    component: chatView,
    props: true,
  },
  {
    path: '/page/:alias',
    name: 'staticPage',
    component: StaticPageView,
    props: true,
  },
  {
    path: '/404/chat',
    name: 'chat404',
    component: chatView,
  },
  {
    path: '/404',
    name: 'page404',
    component: PageSystem404,
  },
  {
    path: '/',
    name: 'home',
    // redirect: '/dashboard',
    // redirect: (to) => {
    redirect: () => {
      if (localStorage.getItem('exhID')) {
        return '/dashboard';
      }
      return '/events';
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
