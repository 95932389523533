<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="match-form__wrapper">
    <div class="match-form__time-options">
      <nav-btn-tab
        v-if="optionsDays.length"
        :idNavTab="'nav-tab-days'"
        :tabs="optionsDays"
        :cur-val="selectedDay"
        :highlight-val="highlightDate.day"
        @change-value="updSelectedDay"
      />
      <nav-btn-tab
        v-if="optionsTime.length"
        :idNavTab="'nav-tab-times'"
        :tabs="optionsTime"
        :cur-val="selectedTime"
        :highlight-val="highlightDate.time"
        @change-value="updSelectedTime"
      />
    </div>
    <up-card class="match-form">
      <h2
        v-if="whoNameUI"
        v-html="whoNameUI"
        class="meeting-who"
        @click="handleLinkToProfile"
      />
      <p v-if="!meetingUiDetailed.isStatusText">
        <strong>{{ meetingUiDetailed.statusText }}</strong>
      </p>
      <div class="up-container">
        <div class="up-row">
          <p>{{ $t('meeting.meeting_time') }}</p>
        </div>
        <div class="up-row">
          <p class="meet-detail">
            <strong>{{ selectedDate.day }}</strong>
            <strong>{{ selectedDate.time }}</strong>
          </p>
          <p class="meet-duration"><strong>
            {{ $t('meeting.meeting_duration').replace('__minutes__', '15') }}
          </strong></p>
        </div>
        <div
          v-if="validateMeet.isInvalid"
          class="up-row"
        >
          <p class="note-validation">
            <span class="note note-invalid">{{ validateMeet.message }}</span>
          </p>
        </div>
      </div>
      <textarea
        v-if="meetingUiDetailed.is_textare"
        v-model="meetingUiProp.message"
        :placeholder="$t('meeting.message_placeholder')"
      />
      <div
        v-else
        >
        <span>{{ $t('meeting.message_label') }}</span>
        <div class="match-form__message">
          {{ meetingUiProp.message }}
        </div>
      </div>
      <div
        v-if="!BtnUiText.isBtnBack"
        class="up-btn__line base-3-6-3"
      >
        <button
          v-if="BtnUiText.isBtnDecline"
          class="up-btn up-btn-primary"
          @click="handleDeclineMeeting"
        >
          {{ BtnUiText.btnDeclineText }}
        </button>
        <button
          v-if="BtnUiText.isBtnAprove"
          class="up-btn"
          :class="{
            'up-btn-accent': meetingUiProp.approve_me !== true,
            'up-btn-disabled': meetingUiProp.approve_me === true,
          }"
          :disabled="meetingUiProp.approve_me"
          @click="handleSendMeeting"
        >
          {{ BtnUiText.btnAproveText }}
        </button>
        <button
          v-if="BtnUiText.isBtnReplace"
          class="up-btn up-btn-accent"
          @click="handleSendMeeting"
        >
          {{ $t('meeting.button.reschedule') }}
        </button>
        <button
          v-if="BtnUiText.isBtnReplace"
          class="up-btn up-btn-primary"
          @click="dropChange"
        >
          {{ $t('meeting.button.reset_changes') }}
        </button>
      </div>
      <div
        v-else
        class="up-btn__line base-3-6-3"
      >
        <router-link
          :to="{ name: 'userCalendar' }"
          class="up-btn up-btn-primary"
        >
          {{ $t('meeting.button.back') }}
        </router-link>
      </div>
    </up-card>
  </div>
</template>
<script>
// GET
// + mm/user/:id/timeslot
// + mm/meeting/:id
// POST
// + mm/user/:id/timereserve {user_id, timestamp}
// + mm/meeting/create { ...property }
// handleDUT
// + mm/meeting/:id { ...property }
// DELETE
// mm/meeting/:id { ...property }
import { DateTime } from 'luxon';
import appBaseNav from '@/components/mixins/appBaseNav';
import meeting from '@/components/mixins/api/apiMethodsMeeting';
import participants from '@/components/mixins/methodsParticipants';

export default {
  new: 'EditMatchView',
  props: {
    matchId: {
      default: null,
    },
    locale: {
      type: String,
    },
    participantID: {
      default: null,
    },
  },
  data() {
    return {
      meetingProp: {
        uuid: null, // to url
        invited_user_id: null, // send as user
        approve_me: null,
        approve_who: null,
        start_ts: null, // send as start
        duration_ts: null,
        message: null,
      },
      meetingUiProp: {
        ident: null,
        invited_user_id: null,
        whoName: null,
        approve_me: null,
        approve_who: null,
        message: null,
      },
      timeSlot: [],
      selectedDay: null,
      selectedTime: null,
    };
  },
  mixins: [appBaseNav, meeting, participants],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('meeting.header_title_new_meeting'));
    },
  },
  computed: {
    pageTitle() {
      if (this.meetingUiProp.approve_who === null && this.meetingUiProp.approve_me === null) {
        return this.$t('meeting.header_title_new_meeting');
      }
      if (this.meetingUiProp.approve_who === true && this.meetingUiProp.approve_me === null) {
        return this.$t('meeting.header_title_aprove_meeting');
      }
      if (this.meetingUiProp.approve_who === null && this.meetingUiProp.approve_me === true) {
        return this.$t('meeting.header_title_edit_meeting');
      }
      return this.$t('meeting.header_title');
    },
    optionsDays() {
      return this.timeSlot.map((itemDay) => {
        const ts = DateTime
          .fromMillis(itemDay[0].time)
          .setZone(this.$store.getters.getTime_UTC)
          .startOf('day');
        return {
          name: ts.setLocale(this.$store.getters.getLang).toFormat('d MMMM'),
          value: ts.toMillis(),
        };
      });
    },
    indOfDay() {
      const ind = this.optionsDays.findIndex((day) => day.value === this.selectedDay);
      return ind !== -1 ? ind : 0;
    },
    optionsTime() {
      const slots = Array.isArray(this.timeSlot[this.indOfDay])
        ? [...this.timeSlot[this.indOfDay]] : [];
      return slots.map((itemTime) => {
        const ts = DateTime
          .fromMillis(itemTime.time)
          .setZone(this.$store.getters.getTime_UTC);
        return {
          name: ts.setLocale(this.$store.getters.getLang).setZone(this.$store.getters.getTime_UTC).toFormat('H:mm'),
          value: ts.toMillis(),
          disabled: itemTime.disabled,
        };
      });
    },
    selectedDate: {
      get() {
        let day = this.$t('meeting.set_time.set_date');
        let time = this.$t('meeting.set_time.set_time');
        if (this.selectedDay) {
          const dt = DateTime.fromMillis(this.selectedDay).setZone(this.$store.getters.getTime_UTC);
          day = dt.setLocale(this.$store.getters.getLang).toFormat('d MMMM, EEEE, ');
        }
        if (this.selectedTime) {
          const dt = DateTime.fromMillis(this.selectedTime)
            .setZone(this.$store.getters.getTime_UTC);
          time = dt.setLocale(this.$store.getters.getLang).toFormat('H:mm,');
        }
        return { day, time };
      },
    },
    highlightDate: {
      get() {
        const DAY = this.meetingProp.start_ts ? DateTime.fromMillis(this.meetingProp.start_ts)
          .setZone(this.$store.getters.getTime_UTC)
          .startOf('day')
          .toMillis() : null;
        const TIME = this.meetingProp.start_ts ? this.meetingProp.start_ts : null;

        return { day: DAY, time: TIME };
      },
    },
    selectedDateToTs() {
      return this.selectedTime;
    },
    validateMeet() {
      const validate = {
        isInvalid: false,
        message: null,
      };
      if (!this.selectedDay || !this.selectedTime) {
        let validateMsg = '';
        if (!this.selectedDay) validateMsg = this.$t('meeting.set_time.date');
        if (!this.selectedTime) validateMsg += validateMsg === null ? this.$t('meeting.set_time.time') : ` ${this.$t('meeting.set_time.and')} ${this.$t('meeting.set_time.time')}`;

        if (validateMsg) {
          validate.isInvalid = true;
          validate.message = this.$t('meeting.set_time.title').replace('__options__', validateMsg);
        }
      } else {
        const slot = this.optionsTime
          .find((itemTime) => itemTime.value === this.selectedTime);
        if (slot?.disabled) {
          validate.isInvalid = true;
          validate.message = this.$t('meeting.time_slot_taken');
        }
      }

      return validate;
    },
    whoNameUI() {
      if (this.matchId) {
        const title = this.meetingUiProp.approve_who
          ? this.$t('meeting.propose_meeting')
          : this.$t('meeting.meeting_with');
        return this.meetingUiProp.whoName
          ? title.replace('__name__', `<span><i class="exu-user"></i> ${this.meetingUiProp.whoName}</span>`)
          : null;
      }
      const title = this.$t('meeting.invitation_sent');
      return this.meetingUiProp.whoName ? title.replace('__name__', this.meetingUiProp.whoName) : null;
    },
    meetStatus() {
      if (this.meetingUiProp.approve_who && this.meetingUiProp.approve_me) return this.$t('meeting.meeting_confirmed');
      if (this.meetingUiProp.approve_who === null && this.meetingUiProp.approve_me) return this.$t('meeting.waiting_for_confirmation');
      if (this.meetingUiProp.approve_me === false) return this.$t('meeting.you_declined');
      if (this.meetingUiProp.approve_who === false) return this.$t('meeting.offer_declined');
      return this.$t('meeting.wait_for_your_reply');
    },
    meetingUiDetailed() {
      const TITLE_DETAILED = (this.meetingUiProp.approve_who && this.meetingUiProp.approve_me)
       || this.meetingUiProp.approve_who;
      return {
        // eslint-disable-next-line max-len
        isStatusText: this.meetingUiProp.approve_who === null && this.meetingUiProp.approve_me === null,
        statusText: this.meetStatus,
        is_textare: !TITLE_DETAILED && this.meetingUiProp.approve_me === null,
      };
    },
    BtnUiText() {
      // eslint-disable-next-line max-len
      const BTN_DETAILED = this.meetingProp.start_ts !== null && this.meetingProp.start_ts !== this.selectedDateToTs;
      return {
        // eslint-disable-next-line max-len
        isBtnAprove: (this.meetingUiProp.approve_me === null || this.meetingUiProp.approve_who === null) && !BTN_DETAILED,
        btnAproveText: this.meetingUiProp.approve_who ? this.$t('meeting.button.accept') : this.$t('meeting.button.send'),
        isBtnReplace: BTN_DETAILED,
        btnReplaceText: this.$t('meeting.button.reschedule'),
        isBtnDecline: !BTN_DETAILED,
        btnDeclineText: this.meetingUiProp.approve_who && !this.meetingUiProp.approve_me ? this.$t('meeting.button.decline') : this.$t('meeting.button.cancel'),
        isBtnDropText: BTN_DETAILED,
        BtnDropText: this.$t('meeting.button.reset_changes'),
        // eslint-disable-next-line max-len
        isBtnBack: !BTN_DETAILED && (this.meetingUiProp.approve_me === false || this.meetingUiProp.approve_who === false),
      };
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle);
    this.meetingTimeSlot()
      .then((data) => {
        // console.log(data);
        this.timeSlot = data;
      })
      .catch((error) => {
        console.error(error);
      });
    if (this.matchId) {
      this.getMeeting(this.matchId)
        .then((data) => {
          this.selectedDay = DateTime.fromMillis(data.start_ts)
            .setZone(this.$store.getters.getTime_UTC)
            .startOf('day')
            .toMillis();
          this.selectedTime = data.start_ts;
          this.meetingUiProp.ident = data.ident;
          this.meetingUiProp.whoName = data.invited[0].name;
          this.meetingUiProp.invited_user_id = data.invited[0].id;
          this.meetingUiProp.approve_me = data.approve_me;
          this.meetingUiProp.approve_who = data.approve_who;
          this.meetingUiProp.message = data.message;

          this.meetingProp.start_ts = data.start_ts;
          this.$store.commit('setPageTitle', this.pageTitle);
        })
        .catch();
    } else {
      this.getParticipantPersone(this.participantID)
        .then((results) => {
          this.meetingUiProp.whoName = results.name;
          this.meetingUiProp.approve_me = null;
          this.meetingUiProp.invited_user_id = this.participantID;

          this.$store.commit('setPageTitle', this.pageTitle);
        });
    }
  },
  methods: {
    updSelectedDay(ts) {
      this.selectedDay = ts;
      if (this.selectedTime) {
        const { year, month, day } = DateTime.fromMillis(ts).toObject();
        this.selectedTime = DateTime.fromMillis(this.selectedTime)
          .setZone(this.$store.getters.getTime_UTC)
          .set({ year, month, day }).toMillis();
      }
    },
    updSelectedTime(time) {
      this.selectedTime = time;
    },
    dropChange() {
      const ts = DateTime.fromMillis(this.meetingProp.start_ts)
        .setZone(this.$store.getters.getTime_UTC)
        .startOf('day')
        .toMillis();
      this.updSelectedDay(ts);
      this.updSelectedTime(this.meetingProp.start_ts);
    },
    handleSendMeeting() {
      const DATA_MEET = this.matchId && this.meetingProp.start_ts === this.selectedDateToTs ? {
        approve_me: true,
      } : {
        start_ts: this.selectedTime, // send as start
      };
      const SAVE_DATA = this.matchId ? {
        uuid: this.matchId, // to url
        ...DATA_MEET,
      } : {
        invited_user_id: this.meetingUiProp.invited_user_id, // send as user
        start_ts: this.selectedTime, // send as start
        message: this.meetingUiProp.message,
        duration_ts: 900000,
      };
      this.saveMeeting({
        ...SAVE_DATA,
      })
        .then((results) => {
          const IDENT = results?.ident ? results.ident : this.meetingUiProp.ident;
          if (IDENT) {
            this.$router.push({
              name: 'userCalendar',
              hash: `#mm-${IDENT}`,
            });
          } else {
            this.$router.push({
              name: 'userCalendar',
            });
          }
        })
        .catch();
    },
    handleLinkToProfile() {
      this.$router.push({ name: 'participantProfile', params: { id: this.meetingUiProp.invited_user_id } });
    },
    handleDeclineMeeting() {
      this.declineMeeting(this.matchId)
        .then(() => {
          this.$router.push({
            name: 'userCalendar',
          });
        })
        .catch();
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/style/variables.scss';

@import '@/assets/style/form.scss';

.match-form {
  font-size: 14px;
  color: $color-text;
  &__wrapper {
    display: flex;
    max-width: 760px;
    flex-wrap: wrap;
    padding: 12px;
    margin: 0 auto;
    gap: 12px;

    & > * {
      flex: 1 0 100%;
    }
    & > .upCard {
      border-radius: 16px;
    }
  }
  &__time-options {

  }
  & .btn__line {
    &:last-child {
      margin-bottom: $padding-step * -1;
    }
  }
  & h1 {
    font-size: 18px;
  }
  & h2 {
    font-size: 16px;
    color: $color-primary;
    margin-bottom: 0;

    &.meeting-who {
      span {
        display: inline-block;
        border-bottom: 1px dashed #356899;
      }
    }
  }
  & p {
    margin: 0;
    &.meet-duration {
      width: 100%;
      text-align: right;

      @media (min-width: 350px) {
        & {
          width: auto;
        }
      }
    }
    &.meet-detail {
      display: flex;
      flex-direction: column;
      @media (min-width: 350px) {
        & {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      & > strong {
        white-space: nowrap;
      }
    }
  }
  & textarea {
    height: 12em;
    margin: 0 -4px;
    padding: 8px 4px;
    border: 1px solid $color-default;
    border-radius: 8px;
    &::placeholder {
      color: $color-default;
    }
  }
  &__message {
    position: relative;
    padding: 12px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid $color-default;

    // &::before {
    //   content: 'Сообщение';
    //   position: absolute;
    //   top: 0;
    //   left: 6px;
    //   transform: translateY(-100%);
    //   font-size: 14px;
    //   color: $color-default;
    //   background: #fefefe;
    //   padding: .25rem 6px .1rem;
    // }
  }
  .note {
    white-space: nowrap;
    font-size: .8rem;

    &-validation {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    &-invalid {
      color: $color-text-danger;
    }
  }
}
</style>
